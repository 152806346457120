@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;
    width: 100%;
    height: 100%;

    .inner {
        transition: opacity 0.2s ease-out;
    }
}
