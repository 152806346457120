@import '~@micromag/core/scss/styles';
@import '~@micromag/viewer/scss/styles';

/**
 * Commons
 */
@import 'commons/variables';
@import 'commons/placeholders';
@import 'commons/mixins';
@import 'commons/fonts';
@import 'commons/medias_queries';
@import 'commons/reset';
@import 'commons/general';

html, body, #app {
    width: 100%;
    height: 100%;
}

body {
    background: rgb(28, 28, 28);
    color: white;
    font-family: $font-text;
}

button {
    margin: 0;
    border: 0;
    color: inherit;
    background: none;
    cursor: pointer;
}

/**
 * Views
 */
