@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

/* font-weight fix */
:global(.micromag-element-text-container) p {
    font-weight: inherit;
}

/* hide hint arrow on first screen */
:global(.micromag-viewer-partials-arrow-hint-inner) {
    visibility: hidden;
}

.container {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
}

.languageToggle {
    position: absolute;
    top: 0.35em;
    right: 5.5em;
    z-index: 10;

    background-color: rgba(128, 128, 128, 0.5);
    border-radius: 1vh;

    display: flex;
    flex-direction: row;
    justify-items: center;

    button {
        font-size: 1em;
        padding: 0.15em 0.5em;
    }
}

.tapHint {
    position: absolute;
    top: 57%;
    right: 5%;
    z-index: 10;
    cursor: pointer;
    pointer-events: none;

    img {
        width: 2.5em;
        animation: 1s ease-in-out 0s infinite alternate tapHintAnimation;
    }
}

@keyframes tapHintAnimation {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.2);
    }
}

.active {
    background-color: rgba(128, 128, 128, 0.7);
    border-radius: 1vh;
}

@media screen and (orientation: landscape) {
    .tapHint {
        visibility: hidden;
    }
}
